import React from "react";
import facebook from '../img/social/facebook.svg'

export default function InfoBar(){
  return (
    // Important! Always set the container height explicitly
    <div className="infobar" style={
        {
            backgroundColor: '#0067AC',
            height: '2em',
            display: "flex",
            flexDirection: "row-reverse",
            position: "fixed",
            right: 0,
            left: 0,
            zIndex:30
        }}>
        <div style={{
            color: "white",
            lineHeight: "2em",
            order: 1
        }}>
            <span 
            >
                Call Today! 
                <a style={{
                color: "white"
                
            }}
                href="tel:1-205-365-0943"> 1-205-365-0943</a>
            </span>
        </div>
        <div className="icon" style={{
            float: "right",
            margin: "auto 10px"
        }}>
            <img href="https://www.facebook.com/Corkys-Sod-Farm-106004277772139" src={facebook} alt="Github" />
        </div>
    </div>
  );
}